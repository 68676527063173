import React, {useContext} from "react";
import { FirebaseUserContext } from '../GamesLayout'

import { navigate } from "gatsby";

export default function AdminRoute({ children }) {
  const userContext = useContext(FirebaseUserContext)
  const isAdmin = (userContext && userContext.userRole === "admin")
  if (userContext && userContext.userRole && userContext.userRole !== "admin") {
    console.log('rejected', userContext)
    navigate("/");
    return null;
  }
  return (
    <>
      {!userContext.userRole && <div>loading admin</div>}
      {isAdmin && <>{children}</>}
    </>
  
  );
}

export function WithRole({ children, role }) {
  const userContext = useContext(FirebaseUserContext)
  const hasRole = (userContext && userContext.userRole === role)

  if (userContext && userContext.userRole && userContext.userRole !== role) {
    console.log('rejected', userContext)
    navigate("/");
    return null;
  }
  return (
    <>
      {!userContext.userRole && <div>loading</div>}
      {hasRole && <>{children}</>}
    </>
  
  );
}