import React from "react"
import { Router } from "@reach/router"
import Layout from "../../../components/GamesLayout"
import Default from "../../../app/components/Default"
import Profile from "../../../app/components/Profile"
import Collection from "../../../app/components/Collection"
import { WithRole } from '../../../components/Routing'
import GamesLists from '../../../components/GamesLists'
const App = () => {
  return (
    <Layout displayContact={false} displayDataFiscal={false} withSearch={false} useIndex={true}>
      <Router basepath="/app/u">
        <Profile path="/profile" />
        {/* <Details path="/details" />
        <Login path="/login" /> */}
        <Default path="/" />
        <Collection path="/coleccion" />
        <WithRole role="admin" path="/listas">
          <GamesLists path="/" />
        </WithRole>        
      </Router>
    </Layout>
  )
}
export default App


// http://localhost:8000/evento/olivos-1657238400000-3cKktgmAcDpgx7gmjfql2d/
// http://localhost:8000/evento/olivos-1663286400000-3cKktgmAcDpgx7gmjfql2d/