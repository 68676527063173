import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

import { useStyles, GameListModal } from '../../app/components/Collection';
import { FirebaseUserContext, UserGamesContext } from '../GamesLayout';

function GamesLists(props) {
  const classes = useStyles()
  const userGames = useContext(UserGamesContext)
  const userContext = useContext(FirebaseUserContext)
  const [open, setOpen] = useState(false);
  const [listName, setListName] = useState('');
  const [list, setList] = useState();
  const [newListColor, setNewListColor] = useState(0);

  const handleClickOpen = (list) => () => {
    setListName(list.name)
    setList(list)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    console.log('userGames', userGames)
    await userGames.updateList(list.id, {name: listName, color: newListColor})
    userGames.setUserLists(null)
    setOpen(false);
  }

  const onChangeListName = (ev) => {
    ev.persist()
    ev.preventDefault()
    setListName(ev.target.value)
  }

  const rotateColor = () => {
    setNewListColor((prev) => {
      const next = (prev < userGames.colors.length - 1) ? prev + 1:0
      return next
    })
  }

  console.log('userContext', userContext)
  console.log('userGames', userGames)
  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Box m={1}>
          <Typography variant="h4" component="h4" gutterBottom>
            Listas de juegos
          </Typography>

          </Box>
        </Grid>
        <Grid item>
            {userGames && userGames.userLists && userGames.userLists.map((list) => {
              console.log('list', list)
              return (
                <Grid key={list.key} container direction="row" alignItems='center'>
                  <Grid item><Box m={1}><Avatar variant="square" className={`${classes.medium} ${classes[userGames.colors[list.color || 0]]}`}><LibraryAddCheckIcon /></Avatar></Box></Grid>
                  <Grid item><Box m={1}><Typography variant="h6" component="h6">{list.name}</Typography></Box></Grid>
                  <Grid item><EditTwoToneIcon onClick={handleClickOpen(list)} /></Grid>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
      <GameListModal
            userGames={userGames}
            open={open}
            rotateColor={rotateColor}
            classes={classes}
            newListColor={newListColor}
            handleClose={handleClose}
            listName={listName}
            onChangeListName={onChangeListName}
            handleCreate={handleCreate}
            title="Modificar Lista"
            text="Podes cambiar el nombre y el color del ícono de la lista."
          />

    </>
  )
}

export default GamesLists;
